import React, { useEffect } from 'react';
import Logo from '../assets/images/ustore.png';
import { getCustomerProfile, mergeCustomerAndAnonCart } from '../state/user/user.service';
import {
  getLocalStorageItem,
  getQueryVariable,
  removeLocalStorageItem,
  setLocalStorageItem
} from "../state/utils";
import { I18nextContext, useI18next } from "gatsby-plugin-react-i18next";
import { startOfDay } from "date-fns";

const ProcessLoginPage: React.FC = (props: any) => {
  const { language } = React.useContext(I18nextContext);
  const { navigate } = useI18next();
  const accessToken = getQueryVariable('accessToken', props.location)
  useEffect(() => {
    const processLogin = async () => {
      if (!accessToken) {
        await navigate('/login/');
      } else {
        setLocalStorageItem('uStoreAccessToken', accessToken);
        setLocalStorageItem('uStoreLSTime', startOfDay(new Date()))

        if (getLocalStorageItem('uStoreAnonCartId')) {
          await mergeCustomerAndAnonCart();
        }
        await getCustomerProfile();

        await navigate(getLocalStorageItem('uStoreAccessRedirectBackTo') || '/shop/');
      }
    };

    processLogin().then(() => {
      removeLocalStorageItem('uStoreAnonCartId');
      removeLocalStorageItem('uStoreAccessRedirectBackTo');
    });
  }, []);

  return (
    <div style={{
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      height: '100vh',
      display: 'flex'
    }}>
      <div><img src={Logo} alt="ustore" className="animate-breathing"/></div>
      <p style={{
        fontSize: '14px',
        textAlign: 'center',
      }}>{ language === 'en' ? 'please wait' : '请稍等' }</p>
    </div>
  );
};

export default ProcessLoginPage;
